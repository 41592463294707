/**
 * UI Initiative Material You Slider
 *
 * Material You Carousel made with Swiper
 *
 * https://uiinitiative.com
 *
 * Copyright 2024 UI Initiative
 *
 * Released under the UI Initiative Regular License
 *
 * April 29, 2024
 */

:root {
  --swiper-material-scale: 1;
  --swiper-material-slide-size: 100%;
  --swiper-material-slide-border-radius: 32px;
}

.swiper-material-wrapper {
  border-radius: var(--swiper-material-slide-border-radius);
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  box-sizing: border-box;
  pointer-events: auto;
}

.swiper-material-content {
  position: absolute;
  transform: translate3d(0, 0, 0);
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-horizontal .swiper-material-content {
  height: 100%;
  width: var(--swiper-material-slide-size);
  left: calc(50% - var(--swiper-material-slide-size) / 2);
  top: 0;
}

.swiper-vertical .swiper-material-content {
  width: 100%;
  height: var(--swiper-material-slide-size);
  top: calc(50% - var(--swiper-material-slide-size) / 2);
  left: 0;
}